import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import SelectCategory from "./pages/SelectCategory";
import Question from "./pages/Question";
import DfdDiagram from "./pages/DfdDiagram";
import QuickSurvey from "./pages/QuickSurvey";
import FullSurvey from "./pages/FullSurvey";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/question":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/category" element={<SelectCategory />} />
      <Route path="/question" element={<Question />} />
      <Route path="/diagram" element={<DfdDiagram />} />
      <Route path="/fullsurvey" element={<FullSurvey />} />
      <Route path="/" element={<QuickSurvey />} />
    </Routes>
  );
}
export default App;
