import React, { useState, useEffect } from "react";

import { commonService } from "../services/commonService";

import { useNavigate } from "react-router-dom";

const OperationalManagementContainer = ({ allQuestions, categories }) => {
  if (!allQuestions || allQuestions.length === 0) {
    return <p>No values available</p>;
  }

  if (!categories || categories.length === 0) {
    return <p>No values available</p>;
  }
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();

  const [currentCategory, setCurrentCategory] = useState(
    categories[0].CategoryName
  );

  const currentQuestion = allQuestions[currentQuestionIndex];

  const [apiData, setApiData] = useState([]);
  const [category, setcategory] = useState([]);

  const [lastSelectedOption, setLastSelectedOption] = useState(null);
  const [lastSelectedQuestion, setLastSelectedQuestion] = useState(null);
  const [dataArray, setDataArray] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [backButtonDisabled, setBackButtonDisabled] = useState(true);
  const [percent, setPercent] = useState(0);
  const [percents, setPercents] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await commonService.get("options"); // Update the endpoint accordingly
        setApiData(result);
        // Update the endpoint accordingly
        const apiCategory = await commonService.get("category");
        setcategory(apiCategory);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if needed
      }

      const handleBeforeUnload = async () => {
        // Make your API call here
        try {
          // Replace the URL with your API endpoint
          const data = JSON.parse(localStorage.getItem("dataArray"));

          commonService.post("usersTempAnswer", data);

          // Handle the response as needed
        } catch (error) {
          console.error("Error making API call:", error);
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup the event listener when the component is unmounted
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs once on mount

  const saveData = async (postData) => {
    try {
      // Make the POST request using commonService
      const response = await commonService.post("usersanswer", postData);

      // Handle the response as needed
      console.log("POST request successful:", response);
      navigate("/diagram");
    } catch (error) {
      // Handle errors
      console.error("Error making POST request:", error);
    }
  };

  const handleNextClick = () => {
    const userEmail = localStorage.getItem("userEmail");
    if (
      dataArray.filter(
        (x, index) => x.questionId == allQuestions[currentQuestionIndex + 1]?.id
      ) &&
      dataArray.filter(
        (x, index) => x.questionId == allQuestions[currentQuestionIndex + 1]?.id
      )[0]
    ) {
      setLastSelectedQuestion(
        dataArray.filter(
          (x, index) =>
            x.questionId == allQuestions[currentQuestionIndex + 1].id
        )[0].questionId
      );
      setLastSelectedOption(
        dataArray.filter(
          (x, index) =>
            x.questionId == allQuestions[currentQuestionIndex + 1].id
        )[0].optionId
      );
    }
    const userId = localStorage.getItem("userId")
      ? parseInt(localStorage.getItem("userId"))
      : "";
    var percetage = percent;
    var percetages = percents;
    if (localStorage.getItem("survey") == "fullsurvey") {
      setPercent(percetage + 0.153);
      setPercents(percetages + 1.53 >= 98 ? 100 : percetages + 1.53);
      if (percetages + 1.53 >= 98) {
        setBackButtonDisabled(true);
      }
    } else {
      setPercent(percetage + 0.66);
      setPercents(percetages + 6.6 >= 98 ? 100 : percetages + 6.6);
    }

    if (!dataArray.find((x) => x.questionId == lastSelectedQuestion)) {
      setDataArray((prevDataArray) => [
        ...prevDataArray,
        {
          questionId: lastSelectedQuestion,
          optionId: lastSelectedOption,
          categoryId: categories[Math.floor(currentQuestionIndex / 5)]
            ? categories[Math.floor(currentQuestionIndex / 5)]?.id
            : categories[Math.floor(currentQuestionIndex / 5)]?.id,
          userEmail: userEmail,
          userId: userId,
          createdDate: new Date().toISOString(),
        },
      ]);
    }
    localStorage.setItem("dataArray", JSON.stringify(dataArray));

    // setLastSelectedOption(null);
    if (currentQuestionIndex + 1 == allQuestions.length) {
      const obj = {
        questionId: lastSelectedQuestion,
        optionId: lastSelectedOption,
        categoryId: categories[Math.floor(currentQuestionIndex / 5)]
          ? categories[Math.floor(currentQuestionIndex / 5)]?.id
          : categories[Math.floor(currentQuestionIndex / 5)]?.id,
        userEmail: userEmail,
        userId: userId,
        createdDate: new Date().toISOString(),
      };
      dataArray.push(obj);
      const newData = [...dataArray, obj];

      const uniqueQuestionIds = {}; // Helper object to track unique questionIds
const filtereddataArray = dataArray.filter(obj => {
  if (!uniqueQuestionIds[obj.questionId]) {
    uniqueQuestionIds[obj.questionId] = true;
    return true; // Include the object if questionId is unique
  }
  return false; // Exclude the object if questionId is already encountered
});

      const categoriesList = JSON.parse(localStorage.getItem("categoryArray"));
      var diagramData = [];
      categoriesList.forEach((element) => {
        var obj = {
          CategoryName: category.find((x) => x.id == element).CategoryName,
          QuestionsData: filtereddataArray
            .filter((x) => x.categoryId === element)
            .map((x) => ({
              question: allQuestions.find((y) => y.id == x.questionId)
                .OptionWord,
              colorNm: apiData.find((y) => y.id == x.optionId).optionColour,
            })),
          ColorData: [
            dataArray
              .filter((x) => x.categoryId == element)
              .map((x) => apiData.find((y) => y.id == x.optionId).optionColour),
          ],
        };
        diagramData.push(obj);

        if (diagramData.length == categories.length) {
          localStorage.setItem("Diagram", JSON.stringify(diagramData));
        }
      });

      saveData(dataArray);
    }

    if (currentQuestionIndex < allQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      if (categories[Math.floor((currentQuestionIndex + 1) / 5)].CategoryName) {
        setCurrentCategory(
          categories[Math.floor((currentQuestionIndex + 1) / 5)].CategoryName
        );
      }
    }
    if (
      dataArray.filter(
        (x, index) => x.questionId == allQuestions[currentQuestionIndex + 1]
      ) &&
      dataArray.filter(
        (x, index) => x.questionId == allQuestions[currentQuestionIndex + 1]?.id
      )[0]
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setBackButtonDisabled(false);
  };

  const handleBackClick = () => {
    const userEmail = localStorage.getItem("userEmail");
    if (currentQuestionIndex == 1) {
      setBackButtonDisabled(true);
    } else {
      setBackButtonDisabled(false);
    }
    const userId = localStorage.getItem("userId")
      ? parseInt(localStorage.getItem("userId"))
      : "";
    var percetage = percent;
    var percetages = percents;
    if (localStorage.getItem("survey") == "fullsurvey") {
      setPercent(percetage - 0.153);
      setPercents(percetages - 1.53 < 0 ? 0 : percetages - 1.53);
    } else {
      setPercent(percetage - 0.66);
      setPercents(percetages - 6.6 < 0 ? 0 : percetages - 6.6);
    }

    localStorage.setItem("dataArray", JSON.stringify(dataArray));

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setLastSelectedOption(dataArray[currentQuestionIndex - 1].optionId);
      setLastSelectedQuestion(dataArray[currentQuestionIndex - 1].questionId);

      setButtonDisabled(false);
      if (categories[Math.floor((currentQuestionIndex - 1) / 5)].CategoryName) {
        setCurrentCategory(
          categories[Math.floor((currentQuestionIndex - 1) / 5)].CategoryName
        );
      }
      //    var array = [...dataArray]; // make a separate copy of the array
      // if (currentQuestionIndex >=1 ) {
      //   array.splice(currentQuestionIndex-1, 1);
      //   setDataArray(array);
      // }
    }
  };

  const handleRadioChange = (optionId, questionId) => {
    setLastSelectedOption(optionId);
    setLastSelectedQuestion(questionId);
    setButtonDisabled(false);
    if (
      (!isButtonDisabled && !backButtonDisabled) ||
      (currentQuestionIndex == 0 && backButtonDisabled && !isButtonDisabled)
    ) {
      dataArray.find((x) => x.questionId == questionId)
        ? (dataArray.find((x) => x.questionId == questionId).optionId =
            optionId)
        : "";
    }
  };

  return (
    //     <div>
    //     <h2>All Questions</h2>
    //     {currentQuestion ? (
    //        <>
    //        {currentQuestion.Ouestion}
    //        </>

    //      ) : (
    //        <p>Loading...</p>
    //      )}
    //       <button onClick={handleNextClick}>Next</button>
    // </div>
    <section className="w-full flex flex-col items-center justify-start py-0 px-4 box-border min-w-[360px] max-w-[1440px] text-left text-5xl text-gray font-inter">
      <div className="w-full flex flex-col items-start justify-center py-16 px-0 box-border gap-[24px] max-w-[1276px]">
        <h3 className="m-0 self-stretch relative text-inherit leading-[140%] font-normal font-inherit">
          {currentCategory}
        </h3>
        {currentQuestion ? (
          <>
            <h2 className="m-0 self-stretch relative text-13xl leading-[140%] font-medium font-inherit">
              {currentQuestion.Ouestion}
            </h2>
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-lg">
              <div
                onClick={() =>
                  handleRadioChange(currentQuestion.Option1, currentQuestion.id)
                }
                className={`self-stretch flex flex-row items-center justify-start gap-[8px] rounded-lg h-20 flex flex-row items-center justify-start p-4 gap-[12px] sm:w-full ${
                  lastSelectedOption === currentQuestion.Option1
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  name={currentQuestion.Ouestion}
                  checked={lastSelectedOption === currentQuestion.Option1}
                  value={currentQuestion.Option1}
                />
                <div className="flex-1 relative leading-[140%]">
                  {
                    apiData.find((x) => x.id == currentQuestion.Option1)
                      ?.optionValue
                  }
                </div>
              </div>
              <div
                onClick={() =>
                  handleRadioChange(currentQuestion.Option2, currentQuestion.id)
                }
                className={`self-stretch flex flex-row items-center justify-start gap-[8px] rounded-lg h-20 flex flex-row items-center justify-start p-4 gap-[12px] sm:w-full ${
                  lastSelectedOption === currentQuestion.Option2
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  name={currentQuestion.Ouestion}
                  checked={lastSelectedOption === currentQuestion.Option2}
                  value={currentQuestion.Option2}
                />
                <div className="flex-1 relative leading-[140%]">
                  {
                    apiData.find((x) => x.id == currentQuestion.Option2)
                      ?.optionValue
                  }
                </div>
              </div>
              <div
                onClick={() =>
                  handleRadioChange(currentQuestion.Option3, currentQuestion.id)
                }
                className={`self-stretch flex flex-row items-center justify-start gap-[8px] rounded-lg h-20 flex flex-row items-center justify-start p-4 gap-[12px] sm:w-full ${
                  lastSelectedOption === currentQuestion.Option3
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  name={currentQuestion.Ouestion}
                  checked={lastSelectedOption === currentQuestion.Option3}
                  value={currentQuestion.Option3}
                />
                <div className="flex-1 relative leading-[140%]">
                  {
                    apiData.find((x) => x.id == currentQuestion.Option3)
                      ?.optionValue
                  }
                </div>
              </div>
              <div
                onClick={() =>
                  handleRadioChange(currentQuestion.Option4, currentQuestion.id)
                }
                className={`self-stretch flex flex-row items-center justify-start gap-[8px] rounded-lg h-20 flex flex-row items-center justify-start p-4 gap-[12px] sm:w-full ${
                  lastSelectedOption === currentQuestion.Option4
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  name={currentQuestion.Ouestion}
                  checked={lastSelectedOption === currentQuestion.Option4}
                  value={currentQuestion.Option4}
                />
                <div className="flex-1 relative leading-[140%]">
                  {
                    apiData.find((x) => x.id == currentQuestion.Option4)
                      ?.optionValue
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}

        <div style={{ overflow: "hidden" ,width:"20%"}}>
          <button
            className={`cursor-pointer [border:none] py-0 px-[52px] ${
              backButtonDisabled ? "bg-darkgray" : "bg-tomato"
            } rounded-lg h-[52px] flex flex-col items-center justify-center box-border`}
            disabled={backButtonDisabled}
            onClick={() => handleBackClick()}
            style={{ float: "left" }}
          >
            <div className="relative text-base leading-[30px] font-semibold font-inter text-white text-center">
              Back
            </div>
          </button>
          <button
            className={`m-2 cursor-pointer [border:none] py-0 px-[52px] ${
              isButtonDisabled ? "bg-darkgray" : "bg-tomato"
            } rounded-lg h-[52px] flex flex-col items-center justify-center box-border`}
            disabled={isButtonDisabled}
            onClick={() => handleNextClick()}
            style={{ float: "right" }}
          >
            <div className="relative text-base leading-[30px] font-semibold font-inter text-white text-center">
              Next
            </div>
          </button>
        </div>
      </div>
      <div className="bg-white w-full flex flex-col items-center justify-start py-8 px-4 box-border text-center text-base text-gray font-inter">
        <div className="w-full flex flex-col items-center justify-center gap-4 max-w-[1276px]">
          <div className="self-stretch relative leading-[140%]">
            Overall Progress {Math.floor(percents)}%
          </div>
          <progress value={percent / 10} />
        </div>
      </div>
    </section>
  );
};

export default OperationalManagementContainer;
