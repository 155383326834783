import React, { useState, useEffect } from "react";
import { commonService } from "../services/commonService";
import html2canvas from "html2canvas";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./DfdDiagram.css";
import "./styles.css";

const DfdDiagram = () => {
  const [apiData, setApiData] = useState(null);
  const [base64Content, setBase64Content] = useState("");
  const [diagramClass, setDiagramClass] = useState("content d-block");
  
  const [frameInputValue, setFrameInputValue] = useState(
    localStorage.getItem("userEmail")
  );
  const [subscribed, setSubscribed] = useState(false);
  const [greenResults, setGreenResult] = useState("");
  const [yellowResult, setYellowResult] = useState("");
  const [redResult, setRedResult] = useState("");

  const [selectedState, setSelectedState] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState([]);

  const [categoryApiData, setCategoryApiData] = useState(null);
  const [diagramItem, setDiagramItem] = useState(null);
  // const diagramItem=[{}];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultdata = await commonService.get("category"); // Update the endpoint accordingly
        setCategoryApiData(resultdata);
        const userId = localStorage.getItem("userId")
          ? parseInt(localStorage.getItem("userId"))
          : "";
        //dipesh@cogentinfomedia.com
        var result;
        if (localStorage.getItem("Diagram")) {
          result = JSON.parse(localStorage.getItem("Diagram")); // Update the endpoint accordingly
          setDiagramItem(result);

          const redQuestions = result.flatMap((category) =>
            category.QuestionsData.filter(
              (question) => question.colorNm == "#E6492D"
            ).map((question) => question.question)
          );
          setRedResult(redQuestions);
          localStorage.setItem("redQuestions", JSON.stringify(redQuestions));
          const greenQuestions = result.flatMap((category) =>
            category.QuestionsData.filter(
              (question) => question.colorNm == "#34AA44"
            ).map((question) => question.question)
          );
          setGreenResult(greenQuestions);
          localStorage.setItem("greenQuestions", greenQuestions.toString());
          const yellowQuestions = result.flatMap((category) =>
            category.QuestionsData.filter(
              (question) => question.colorNm == "#F6AB2F"
            ).map((question) => question.question)
          );
          setYellowResult(yellowQuestions);
          localStorage.setItem(
            "yellowQuestions",
            JSON.stringify(yellowQuestions)
          );
        } else {
          result = await commonService.get("usersanswer"); // Update the endpoint accordingly

          const greenResults = result
            .filter((x) => x.optionId == "#34AA44" && x.userId == userId)
            .map((x) => x.questionId)
            .join(", ");
          setGreenResult(greenResults);
          const yellowResults = result
            .filter((x) => x.optionId == "#F6AB2F" && x.userId == userId)
            .map((x) => x.questionId);
          setYellowResult(yellowResults);
          const redResults = result
            .filter((x) => x.optionId == "#E6492D" && x.userId == userId)
            .map((x) => x.questionId);
          setRedResult(redResults);
          setApiData(result);
          const diagram = [];
          resultdata.forEach((element) => {
            const filteredArray = result
              .filter((x) => x.categoryId == element.id && x.userId == userId)
              .map((x) => ({ questionId: x.questionId, optionId: x.optionId }));
            const obj = {
              CategoryName: element.CategoryName,
              QuestionsData: result
                .filter((x) => x.categoryId == element.id && x.userId == userId)
                .map((x) => ({ question: x.questionId, colorNm: x.optionId })), //result.filter(x=>x.categoryId==element.id && x.userId==userId).flatMap(x=>x.questionId),
              ColorData: result
                .filter((x) => x.categoryId == element.id && x.userId == userId)
                .flatMap((x) => x.optionId),
            };
            if (obj.QuestionsData && obj.QuestionsData.length > 0) {
              diagram.push(obj);
            }
          });
          setDiagramItem(diagram);
        }

        setTimeout(() => {
          convertToBase64();
        }, 3000);
        console.log(diagramItem);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    
    };

    fetchData();
  }, []);

  const data = [
    {
      CategoryName: "Operational Management",
      QuestionsData: [
        "Operational Efficiency Documentation",
        "Process Training Program",
        "Quality Care Implementation",
        "Employee Management Strategy",
        "Goal-Aligned Operations",
      ],
    },
    {
      CategoryName: "Medicaid Waiver Specialization",
      QuestionsData: [
        "Medicaid Program Monitoring",
        "Streamlined Waiver Assistance",
        "Billing Compliance Assurance",
        "Referral Agency Partnerships",
        "Medicaid Audit Management",
      ],
    },
    // Add more departments as needed
  ];

  const renderSection = (section) => (
    <li key={section} className="section">
      <span
        style={{ backgroundColor: section.colorNm, color: "white !important" }}
      >
        {section.question}{" "}
      </span>
    </li>
  );

  const renderDepartment = (department) => (
    <li key={department.CategoryName} className="department">
      <span className="lvl-b">{department.CategoryName}</span>
      <ul className="QuestionsData">
        {department.QuestionsData.map((section) => renderSection(section))}
      </ul>
    </li>
  );

  const onStateSelect = (stateName) => {
    // Check if the item is already in the selectedState array
    if (selectedState.includes(stateName)) {
      // Remove the item from the selectedState array
      setSelectedState((prevselectedState) =>
        prevselectedState.filter(
          (selectedItemId) => selectedItemId !== stateName
        )
      );
    } else {
      setSelectedState((prevselectedState) => [
        ...prevselectedState,
        stateName,
      ]);
    }
  };

  const onBusinessSelect = (busineeName) => {
    // Check if the item is already in the selectedBusiness array
    if (selectedBusiness.includes(busineeName)) {
      // Remove the item from the selectedBusiness array
      setSelectedBusiness((prevselectedBusiness) =>
        prevselectedBusiness.filter(
          (selectedItemId) => selectedItemId !== busineeName
        )
      );
    } else {
      setSelectedBusiness((prevselectedBusiness) => [
        ...prevselectedBusiness,
        busineeName,
      ]);
    }
  };

  const convertToBase64 = () => {
    const element = document.getElementsByClassName("content")[0];

    if (element) {
      html2canvas(element).then((canvas) => {
        const base64 = canvas.toDataURL("image/jpeg");
        setBase64Content(base64);
        setDiagramClass('content d-none');
        if (localStorage.getItem("isEmailSent") != "yes") {
          directSend(base64);
        }

        // Optionally, you can create a download link for the image
        // const downloadLink = document.createElement('a');
        // downloadLink.href = base64;
        // downloadLink.download = 'captured_image.jpg';
        // downloadLink.click();
      });
    } else {
      console.error('Element with class name "content" not found');
    }
  };
  const directSend = (base64data) => {
    if (base64data) {
      var obj = {
        toUserEmail: frameInputValue,
        states: "NO",
        business: "NO",
        imageBase: base64data.split("data:image/jpeg;base64,")[1],

        userId: localStorage.getItem("userId"),
        greenresult: localStorage.getItem("greenQuestions"),
        redresult: JSON.parse(
          localStorage.getItem("redQuestions")
        ).length.toString(),
        yellowresult:
          JSON.parse(localStorage.getItem("yellowQuestions")).length >= 3
            ? JSON.parse(localStorage.getItem("yellowQuestions")).toString()
            : JSON.parse(localStorage.getItem("yellowQuestions")) +
              ", " +
              JSON.parse(localStorage.getItem("redQuestions")),
        surveyType: localStorage.getItem("survey"),
      };
      const response = commonService.post("sendSurvey", obj);
      var userobj = {
        user: parseInt(localStorage.getItem("userId")),
        isEmailSent: null,
        surveyType: localStorage.getItem("survey"),
      };
      if (response) {
        userobj = { ...userobj, isEmailSent: true };
        localStorage.setItem("isEmailSent", "yes");
      } else {
        userobj = { ...userobj, isEmailSent: false };
      }
      commonService.post("usersurvey", userobj);
    } else {
      convertToBase64();
    }
  };

  const bookDemo = () => {
    var obj = {
      UserEmail: localStorage.getItem('userEmail'),
      userId: localStorage.getItem("userId"),
    };
    const response = commonService.post("bookedDemo", obj);
    if (response) {
        openInNewTab();
      }
    // Handle the response as needed
    console.log("POST request successful:", response);
  };

  const subscribe = () => {
    var obj = {
      UserEmail: frameInputValue,
      userId: localStorage.getItem("userId"),
    };
    const response = commonService.post("lettersubscribe", obj);
    if (response) {
      setSubscribed(true);
    }
    // Handle the response as needed
    console.log("POST request successful:", response);
  };

  const sendEmail = () => {
    convertToBase64();

    setTimeout(() => {
      if (
        frameInputValue &&
        selectedState &&
        selectedBusiness &&
        base64Content
      ) {
        var obj = {
          toUserEmail: frameInputValue,
          states: "No",
          business: "NO",
          imageBase: base64Content.split("data:image/jpeg;base64,")[1],
          userId: localStorage.getItem("userId"),
          greenresult: greenResults.toString(),
          redresult: redResult.length.toString(),
          yellowresult:
            yellowResult.length >= 3
              ? yellowResult.toString()
              : yellowResult.toString() + redResult.toString(),
          surveyType: localStorage.getItem("survey"),
        };
        const response = commonService.post("sendSurvey", obj);

        // Handle the response as needed
        console.log("POST request successful:", response);
       
      }
    }, 2000);
  };

  const openInNewTab = () => {
    window.open(
      "https://calendly.com/meet12/caryfy-demo?email=" + frameInputValue,
      "_blank"
    );
  };

  const renderCategory = (categoryData) => (
    <div className="category-itm" key={categoryData.CategoryName}>
      <div className="category-name">{categoryData.CategoryName}</div>
      <ul className="answer-list">
        {categoryData.QuestionsData.map((question, questionIndex) => (
          <li
            className={`answer-itm ${categoryData.ColorData[questionIndex]}`}
            style={{ backgroundColor: question.colorNm }}
            key={questionIndex}
          >
            {question.question}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[360px] text-center text-21xl text-gray-300 font-roboto">
        <Header logo="/header_logo@1x.png" headerWidth="100%" />
        <div className="self-stretch flex flex-col items-center justify-center py-16 px-4">
          <div className="w-full flex flex-col items-center justify-center max-w-[1276px]">
            <div className="w-full flex flex-col items-center justify-center min-w-[328px] max-w-[1060px]">
              <div className="self-stretch flex flex-col items-start justify-start">
                <h1 className="m-0 self-stretch relative text-inherit leading-[120%] font-bold font-inherit">
                  <p className="m-0">Thank you for taking the</p>
                  <p className="m-0">Care Indices Survey</p>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div> <img class='chart' src={base64Content} alt="CareIndices survey result image" /></div>
        <div className={diagramClass} >
          <div class="wrap">
            <div class="category-row first">
              {diagramItem && diagramItem[12] ? (
                <>{renderCategory(diagramItem[12])}</>
              ) : (
                <></>
              )}

              {diagramItem && diagramItem[11] ? (
                <>{renderCategory(diagramItem[11])}</>
              ) : (
                <></>
              )}

              {diagramItem && diagramItem[10] ? (
                <>{renderCategory(diagramItem[10])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[9] ? (
                <>{renderCategory(diagramItem[9])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[8] ? (
                <>{renderCategory(diagramItem[8])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[7] ? (
                <>{renderCategory(diagramItem[7])}</>
              ) : (
                <></>
              )}
            </div>
            <div class="category-row you">
              {diagramItem && diagramItem[6] ? (
                <>{renderCategory(diagramItem[6])}</>
              ) : (
                <></>
              )}
              <div class="category-itm">
                <div class="category-name">You</div>
              </div>
              {diagramItem && diagramItem[5] ? (
                <>{renderCategory(diagramItem[5])}</>
              ) : (
                <></>
              )}
            </div>
            <div class="category-row last">
              {diagramItem && diagramItem[4] ? (
                <>{renderCategory(diagramItem[4])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[0] ? (
                <>{renderCategory(diagramItem[0])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[1] ? (
                <>{renderCategory(diagramItem[1])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[2] ? (
                <>{renderCategory(diagramItem[2])}</>
              ) : (
                <></>
              )}
              {diagramItem && diagramItem[3] ? (
                <>{renderCategory(diagramItem[3])}</>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <section className="self-stretch flex flex-col items-center justify-start py-16 px-4 text-left text-5xl text-gray-300 font-open-sans">
          <div className="w-full flex flex-col items-start justify-start gap-[30px] min-w-[328px] max-w-[1060px]">
            <h2 className="m-0 self-stretch relative text-21xl leading-[160%] font-bold font-inherit text-center">
              Your Quick Check-In Report
            </h2>
            <div className="self-stretch rounded-lg bg-gray-200 flex flex-row items-start justify-start p-12 text-dimgray-200 border-[4px] border-solid border-mediumseagreen">
              <div className="flex-1 flex flex-col items-start justify-center">
                <p className="m-0 self-stretch relative leading-[160%]">
                  <span>{`I wanted to circle back and provide some tailored thoughts based on your Care Indices results. Looking at the Care Indices matrix we created together, I'm excited about the `}</span>
                  <span>{` we're seeing in categories like `}</span>
                  <b className="text-mediumseagreen">
                    {greenResults.toString()}
                  </b>
                  <span>. These areas have clearly been top-of-mind.</span>
                </p>
              </div>
            </div>
            <div className="self-stretch rounded-lg bg-snow flex flex-row items-center justify-start p-12 text-dimgray-200 border-[4px] border-solid border-tomato">
              <div className="flex-1 flex flex-col items-start justify-center">
                <p className="m-0 self-stretch relative leading-[160%]">
                  <span>{`At the same time, `}</span>
                  <b className="text-tomato">{redResult.length}</b>
                  <span>
                    {" "}
                    areas show critical room for improvement. I know how
                    overwhelming it can feel to juggle competing priorities
                    while running on lean bandwidth. The key is not expecting
                    overnight changes or tackling everything at once without
                    support. That will only strain morale and lead to missteps.
                  </span>
                </p>
              </div>
            </div>
            <div className="self-stretch rounded-lg bg-gray-100 flex flex-row items-center justify-start p-12 text-dimgray-100 border-[4px] border-solid border-gold-200">
              <div className="flex-1 flex flex-col items-start justify-center">
                <p className="m-0 self-stretch relative leading-[160%]">
                  <span>{`Instead, I'd recommend picking 1-2 lower scoring domains to focus on each quarter. For example, `}</span>
                  <b className="text-goldenrod">
                    <span>
                      {/* we could start by select 3 from yellow to identify specific
                          bottlenecks to address */}
                      {yellowResult.length <= 3
                        ? yellowResult.toString()
                        : yellowResult + "," + redResult}
                    </span>
                    <span>.</span>
                  </b>
                  {/* <span>
                        <span>{` Or `}</span>
                      </span>
                      <b className="text-goldenrod">
                        Select next 2 from yellow, if not pick one from #E6492D
                      </b> */}
                  <span>
                    {" "}
                    We’ll take it step-by-step, leveraging the latest best
                    practices tailored to organizations like yours.
                  </span>
                </p>
              </div>
            </div>
            <div className="self-stretch rounded-lg bg-white flex flex-row items-center justify-start p-12 border-[4px] border-solid border-dimgray-100">
              <div className="flex-1 flex flex-col items-start justify-center">
                <p className="m-0 self-stretch relative leading-[160%]">
                  Through incremental progress rooted in data-driven empathy and
                  patience, we can elevate performance across the board over
                  time. I'm fully committed to this long-term partnership and
                  improvement journey with you. Does focusing our initial
                  efforts on 1-2 assessment areas resonate as a starting point?
                  How are you feeling about next steps?
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="self-stretch bg-royalblue flex flex-col items-center justify-start py-16 px-4 text-center text-5xl text-white font-roboto">
          <div className="w-full flex flex-col items-center justify-center gap-[30px] max-w-[1060px]">
            <h2 className="m-0 self-stretch relative text-13xl leading-[140%] font-bold font-inherit">
              Let me know if a deeper 1:1 consultation session would be valuable
              to build on assessment findings with priority initiatives for your
              organization's growth stage. You've accomplished so much already!
            </h2>
            <div className="self-stretch rounded-lg bg-gray-700 flex flex-col items-center justify-start p-8 gap-[8px] select-state">
              <b className="self-stretch relative leading-[140%]">
                Select State
              </b>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[16px] text-left text-base font-inter">
              <div onClick={() => onStateSelect("Georgia")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Georgia')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedState.includes('Georgia')}
                    onClick={() => onStateSelect("Georgia")}
                  />
                  <div className="flex-1 relative">Georgia</div>
                </div>
                <div  onClick={() => onStateSelect("Florida")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Florida')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Florida')}
                    type="checkbox"
                    onClick={() => onStateSelect("Florida")}
                  />
                  <div className="flex-1 relative">Florida</div>
                </div>
                <div onClick={() => onStateSelect("Kentucky")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Kentucky')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Kentucky')}
                    type="checkbox"
                    onClick={() => onStateSelect("Kentucky")}
                  />
                  <div className="flex-1 relative">Kentucky</div>
                </div>
                <div onClick={() => onStateSelect("North Carolina")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('North Carolina')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('North Carolina')}
                    type="checkbox"
                    onClick={() => onStateSelect("North Carolina")}
                  />
                  <div className="flex-1 relative">North Carolina</div>
                </div>
                <div onClick={() => onStateSelect("Pennsylvania")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Pennsylvania')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Pennsylvania')}
                    type="checkbox"
                    onClick={() => onStateSelect("Pennsylvania")}
                  />
                  <div className="flex-1 relative">Pennsylvania</div>
                </div>
                <div onClick={() => onStateSelect("Minnesota")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Minnesota')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Minnesota')}
                    type="checkbox"
                    onClick={() => onStateSelect("Minnesota")}
                  />
                  <div className="flex-1 relative">Minnesota</div>
                </div>
                <div onClick={() => onStateSelect("New Jersey")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('New Jersey')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('New Jersey')}
                    type="checkbox"
                    onClick={() => onStateSelect("New Jersey")}
                  />
                  <div className="flex-1 relative">New Jersey</div>
                </div>
                <div onClick={() => onStateSelect("Virginia")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Virginia')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Virginia')}
                    type="checkbox"
                    onClick={() => onStateSelect("Virginia")}
                  />
                  <div className="flex-1 relative">Virginia</div>
                </div>
                <div onClick={() => onStateSelect("West Virginia")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('West Virginia')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('West Virginia')}
                    type="checkbox"
                    onClick={() => onStateSelect("West Virginia")}
                  />
                  <div className="flex-1 relative">West Virginia</div>
                </div>
                <div onClick={() => onStateSelect("Alabama")} className= {`rounded bg-gray-600 w-[186px] h-[70px] flex flex-row items-center justify-start p-2 box-border gap-[8px]  ${
                  selectedState.includes('Alabama')
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    checked={selectedState.includes('Alabama')}
                    type="checkbox"
                    onClick={() => onStateSelect("Alabama")}
                  />
                  <div className="flex-1 relative">Alabama</div>
                </div>
              </div>
            </div>
            <div className="self-stretch rounded-lg bg-gray-700 flex flex-col items-start justify-start p-8 gap-[8px]">
              <b className="self-stretch relative leading-[140%]">
                Select Business Type
              </b>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[16px] text-left text-base font-inter">
                <div 
                 onClick={() => onBusinessSelect("Home health agencies with skilled care")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Home health agencies with skilled care")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Home health agencies with skilled care")}
                    onClick={() =>
                      onBusinessSelect("Home health agencies with skilled care")
                    }
                  />
                  <div className="flex-1 relative">
                    Home health agencies with skilled care
                  </div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Personal care home and assisted living")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Personal care home and assisted living")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Personal care home and assisted living")}
                    onClick={() =>
                      onBusinessSelect("Personal care home and assisted living")
                    }
                  />
                  <div className="flex-1 relative">
                    Personal care home and assisted living
                  </div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Intellectual and Development Disability")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Intellectual and Development Disability")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Intellectual and Development Disability")}
                    onClick={() =>
                      onBusinessSelect(
                        "Intellectual and Development Disability"
                      )
                    }
                  />
                  <div className="flex-1 relative">
                    Intellectual and Development Disability
                  </div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Home care")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Home care")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Home care")}
                    onClick={() => onBusinessSelect("Home care")}
                  />
                  <div className="flex-1 relative">Home care</div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Case Management")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Case Management")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Case Management")}
                    onClick={() => onBusinessSelect("Case Management")}
                  />
                  <div className="flex-1 relative">Case Management</div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Adult Day Health")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Adult Day Health")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Adult Day Health")}
                    onClick={() => onBusinessSelect("Adult Day Health")}
                  />
                  <div className="flex-1 relative">Adult Day Health</div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Pediatric Care")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Pediatric Care")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Pediatric Care")}
                    onClick={() => onBusinessSelect("Pediatric Care")}
                  />
                  <div className="flex-1 relative">Pediatric Care</div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Hospice Care")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Hospice Care")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Hospice Care")}
                    onClick={() => onBusinessSelect("Hospice Care")}
                  />
                  <div className="flex-1 relative">Hospice Care</div>
                </div>
                <div 
                 onClick={() => onBusinessSelect("Palliative Care")} className= {`rounded bg-gray-600 w-[321px] flex flex-row items-center justify-start p-4 box-border gap-[16px]  ${
                  selectedBusiness.includes("Palliative Care")
                    ? "border-2 border-solid border-mediumseagreen"
                    : ""
                }`} 
                >
                  <input
                    className="m-0 relative w-6 h-6 overflow-hidden shrink-0"
                    type="checkbox"
                    checked={selectedBusiness.includes("Palliative Care")}
                    onClick={() => onBusinessSelect("Palliative Care")}
                  />
                  <div className="flex-1 relative">Palliative Care</div>
                </div>
              </div>
            </div>
            <button
              className="cursor-pointer [border:none] py-5 px-16 bg-coral-100 w-full rounded-lg flex flex-row items-center justify-center box-border max-w-[400px] book_consultation_btn"
              onClick={bookDemo}
            >
              <b className="relative text-xl leading-[140%] font-roboto text-white text-left">
                Book a FREE consultation
              </b>
            </button>
          </div>
        </section>
        <section className="self-stretch bg-seashell flex flex-col items-center justify-start py-16 px-4 text-left text-13xl text-gray-300 font-roboto">
        {!subscribed ? (  <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[40px] max-w-[1280px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[16px] min-w-[328px]">
              <h3 className="m-0 self-stretch relative text-inherit leading-[140%] font-bold font-inherit">
                Stay Connected:
              </h3>
              <div className="self-stretch relative text-5xl leading-[140%] text-dimgray-100">
                <p className="m-0">Subscribe to our newsletter</p>
                <p className="m-0">
                  Receive valuable tips, industry insights, and exclusive
                  updates on Care Indices.
                </p>
              </div>
            </div>
            <div>
           
         <div className="flex-1 flex flex-row items-center justify-start min-w-[328px] stay_connected">
         <input
           className="[border:none] [outline:none] font-roboto text-lg bg-white flex-1 flex flex-row items-center justify-start p-4 text-gray-500 input-control-dv"
           placeholder="Enter email address"
           type="email"
           value={frameInputValue}
           onChange={(event) => setFrameInputValue(event.target.value)}
         />
         <button
           className="cursor-pointer [border:none] py-4 px-10 bg-coral-100 flex flex-row items-center justify-start"
           onClick={subscribe}
         >
           <div className="relative text-lg leading-[140%] font-roboto text-white text-left">
             Submit
           </div>
         </button>
       </div>
     
            </div>  
          </div>
           ) : (
            <div className="col-lg-12 col-12 stay-connected-message">
                  <div className="flex-1 flex flex-col items-start justify-center">
                    <p className="m-0 self-stretch relative leading-[160%]">
                      <b className="text-mediumseagreen">
                        Thank you! your email address submitted successfully.
                      </b>
                    </p>
                  </div>
                </div>
          )}
        </section>
        <section className="self-stretch flex flex-col items-center justify-start py-16 px-4 text-left text-sm text-white font-roboto">
          <div className="w-full flex flex-row flex-wrap items-start justify-start gap-[40px] max-w-[1280px] case-studies-dv">
            <div className="flex-1 rounded-lg bg-coral-200 flex flex-col items-start justify-start p-8 box-border gap-[20px] min-w-[328px]">
              <div className="rounded-45xl bg-coral-100 flex flex-col items-start justify-center py-1.5 px-3">
                <b className="relative leading-[140%] uppercase">
                  Case Studies
                </b>
              </div>
              <h3 className="m-0 self-stretch relative text-5xl leading-[140%] font-bold font-inherit text-gray-300">
                Explore real-world examples of how Care Indices has helped other
                long-term care providers achieve success.
              </h3>
            </div>
            <div className="flex-1 rounded-lg bg-coral-200 flex flex-col items-start justify-start p-8 box-border gap-[20px] min-w-[328px]">
              <div className="rounded-45xl bg-coral-100 flex flex-row items-start justify-center py-1.5 px-3">
                <b className="relative leading-[140%] uppercase">
                  White Papers
                </b>
              </div>
              <h3 className="m-0 self-stretch relative text-5xl leading-[140%] font-bold font-inherit text-gray-300">
                Delve deeper into specific topics and industry trends related to
                long-term care performance improvement.
              </h3>
            </div>
            <div className="flex-1 rounded-lg bg-coral-200 flex flex-col items-start justify-start p-8 box-border gap-[20px] min-w-[328px]">
              <div className="rounded-45xl bg-coral-100 flex flex-row items-start justify-center py-1.5 px-3">
                <b className="relative leading-[140%] uppercase">Blog</b>
              </div>
              <h3 className="m-0 self-stretch relative text-5xl leading-[140%] font-bold font-inherit text-gray-300">
                Stay up-to-date with the latest news and insights from Care
                Indices.
              </h3>
            </div>
          </div>
        </section>
        <section className="self-stretch flex flex-col items-center justify-start py-16 px-4 text-center text-13xl text-gray-300 font-roboto">
          <div className="w-full flex flex-col items-start justify-start max-w-[1280px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[140%] font-bold font-inherit">
              Let's work together to build a thriving long-term care landscape.
              Take the first step towards operational excellence. Start your
              assessment today!
            </h2>
          </div>
        </section>
        {/* <footer className="self-stretch bg-darkslategray flex flex-col items-center justify-center py-8 px-4 box-border min-w-[360px] text-left text-lg text-white font-lato">
          <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[8px] max-w-[1280px]">
            <div className="flex flex-row items-center justify-start min-w-[328px]">
              <div className="relative leading-[30px] uppercase font-black">
                Care Indices
              </div>
            </div>
            <div className="flex-1 flex flex-row items-center justify-center min-w-[328px] text-right text-base font-open-sans">
              <div className="flex-1 relative leading-[30px] font-semibold opacity-[0.5]">
                © Copyright CareIndices.com. All rights reserved.
              </div>
            </div>
          </div>
        </footer> */}
        <Footer dimensionText="/footer_logo@1x.png" footerMaxWidth="unset" />
        <div className="btnconsultation_dv">
      <button class="consultation_btn pulse-button cursor-pointer [border:none] py-5 px-16 bg-coral-100 w-full 
      rounded-lg flex flex-row items-center justify-center box-border max-w-[400px]" onClick={bookDemo}>
        <b class="relative text-xl leading-[140%] font-roboto text-white text-center ">
          Book a FREE consultation</b></button>
 </div>
      </div>
      <div>
        {/* <button className="btn btn-primary" onClick={convertToBase64}>
          base64Content
        </button> */}
      </div>
    </>
  );
};

export default DfdDiagram;
