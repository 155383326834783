import { useMemo } from "react";

const Property1CaryfyProperty2 = ({
  property1CaryfyProperty2P,
  property1CaryfyProperty2Width,
  property1CaryfyProperty2Height,
  property1CaryfyProperty2Position,
}) => {
  const property1CaryfyProperty2Style = useMemo(() => {
    return {
      width: property1CaryfyProperty2Width,
      height: property1CaryfyProperty2Height,
      position: property1CaryfyProperty2Position,
    };
  }, [
    property1CaryfyProperty2Width,
    property1CaryfyProperty2Height,
    property1CaryfyProperty2Position,
  ]);

  return (
    <img
      className="w-[351.3px] h-24 object-cover"
      alt=""
      src={property1CaryfyProperty2P}
      style={property1CaryfyProperty2Style}
    />
  );
};

export default Property1CaryfyProperty2;
