import React, { useState } from "react";
import { commonService } from '../services/commonService';

import { useNavigate } from 'react-router-dom';

const FullSurvey = () => {
    const [inputValue, setInputValue] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);
    const selectedItems=[1,2,3,4,5,6,7,8,9,10,11,12,13];
               
    const navigate = useNavigate();
    localStorage.clear();

    const saveData = async (e) => {
        e.preventDefault();
        try {
            if (!inputValue) {
                alert('Please Enter Email');
                return;
            }
           
            const obj = {
                email: inputValue
            }

            if(!isValidEmail){
                alert('Please Enter Valid Email');
                return;
              }
             

            // Make the POST request using commonService
            const response = await commonService.post('user', obj);

            // Handle the response as needed
            console.log('POST request successful:', response.user);
            if(response  && response.user){
                localStorage.setItem('categoryArray', JSON.stringify(selectedItems));
                localStorage.setItem('userEmail',response.user.email);
                localStorage.setItem('userId',response.user.id);
                localStorage.setItem('survey','fullsurvey');
                navigate('/question');
            }

        } catch (error) {
            // Handle errors
            console.error('Error making POST request:', error);
        }
    };
    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setInputValue(newEmail);
    
        // Email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(newEmail));
      };
      return (
        <div
          className="w-full relative bg-white flex flex-col items-center justify-start min-w-[360px] cursor-pointer"
        >
          <header className="self-stretch flex flex-col items-center justify-start py-10 px-4 box-border min-w-[320px] text-left text-5xl text-coral-100 font-lato">
            <div className="w-full flex flex-row flex-wrap items-center justify-start max-w-[1280px]">
              <div className="flex-1 flex flex-col items-start justify-center">
                <div className="relative leading-[30px] uppercase font-black">
                  <span>{`Care `}</span>
                  <span className="text-gray-400">Indices</span>
                </div>
              </div>
            </div>
          </header>
          <section className="w-full [background:linear-gradient(180deg,_rgba(195,_218,_255,_0.31),_#fff)] flex flex-col items-center justify-center py-0 px-4 box-border min-w-[360px] max-w-[1440px] text-left text-21xl text-gray-400 font-open-sans">
            <div className="w-full flex flex-row flex-wrap items-center justify-center py-16 px-0 box-border gap-[24px] max-w-[1060px]">
              <div className="flex-1 flex flex-col items-center justify-center gap-[40px] min-w-[328px]">
                <div className="self-stretch flex flex-col items-center justify-center gap-[24px]">
                  <h1 className="m-0 self-stretch relative text-inherit leading-[140%] font-bold font-inherit">
                    Comprehensive Evaluation
                  </h1>
                  <h3 className="m-0 self-stretch relative text-5xl font-normal font-inherit">
                    We invite you to participate in the Care Indices survey,
                    designed to enhance your long-term care practice.
                  </h3>
                </div>
                <img
                  className="w-[518px] relative h-[300.6px] object-cover graphic"
                  alt=""
                  src="/vector@2x.png"
                />
              </div>
              <div className="flex-1 flex flex-col items-center justify-center gap-[24px] min-w-[328px] text-sm text-dimgray-100">
                <form className="m-0 self-stretch rounded-lg bg-white flex flex-col items-center justify-center p-10 gap-[32px] border-[2px] border-solid border-coral-100">
                  <b className="self-stretch relative text-lg leading-[140%] font-roboto text-gray-300 text-left">
                    <p className="m-0">Enter your email address below</p>
                    <p className="m-0">to start the scorecard</p>
                  </b>
                  <div className="self-stretch flex flex-col items-center justify-center gap-[16px]">
                    <input
                      className="[outline:none] font-roboto text-lg bg-white self-stretch rounded-lg box-border h-16 flex flex-row items-center justify-start py-2 px-6 text-gray-300 border-[1px] border-solid border-gray-300 input-control-dv"
                      placeholder="Enter email address"
                      type="email"
                      value={inputValue}
                                onChange={(event) => handleEmailChange(event)}
                    />
                    <button className="cursor-pointer [border:none] py-[15px] px-[45px] bg-coral-100 self-stretch rounded-lg h-16 flex flex-col items-start justify-start box-border" onClick={saveData}>
                      <div className="self-stretch flex flex-row items-center justify-center">
                        <div className="flex flex-col items-center justify-start">
                          <div className="relative text-xl leading-[30px] font-roboto text-white text-center">
                            Start
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </form>
                <p className="m-0 self-stretch relative">
                  <span className="block">
                    Your self-analysis will directly help you create strategies in
                    business development, quality improvement, communication, and
                    care delivery.
                  </span>
                  <span className="block">
                    This survey takes just a few minutes, but your participation can
                    have a lasting impact on the business.
                  </span>
                  <span className="block">
                    When you select a statement like " I am doing such and
                    such...'', please note that this doesn't mean you personally,
                    but instead refers to you as a spokesperson for the business for
                    which Care Indices are being created.
                  </span>
                </p>
                <h4 className="m-0 self-stretch relative text-xl font-semibold font-inherit text-gray-400">
                  Thank you for helping us serve you better!"
                </h4>
              </div>
            </div>
          </section>
          <footer className="self-stretch bg-darkslategray-100 flex flex-col items-center justify-center py-8 px-4 box-border min-w-[360px] text-left text-lg text-white font-lato">
            <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[8px] max-w-[1280px]">
              <div className="flex flex-row items-center justify-start min-w-[328px]">
                <div className="relative leading-[30px] uppercase font-black">
                  Care Indices
                </div>
              </div>
              <div className="flex-1 flex flex-row items-center justify-center min-w-[328px] text-right text-base font-open-sans">
                <div className="flex-1 relative leading-[30px] font-semibold opacity-[0.5]">
                  © Copyright CareIndices.com. All rights reserved.
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
};

export default FullSurvey;
