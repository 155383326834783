import { useMemo } from "react";
import Property1CaryfyProperty2 from "./Property1CaryfyProperty2";

const Footer = ({ dimensionText, footerMaxWidth }) => {
  const footerStyle = useMemo(() => {
    return {
      maxWidth: footerMaxWidth,
    };
  }, [footerMaxWidth]);

  return (
    // <div
    //   className="bg-whitesmoke flex flex-col items-center justify-center py-8 px-4 box-border min-w-[360px] max-w-[1440px] text-right text-base text-gray font-roboto self-stretch"
    //   style={footerStyle}
    // >
    //   <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[8px] max-w-[1276px]">
    //     <div className="shrink-0 flex flex-row items-center justify-start min-w-[328px]">
    //       <Property1CaryfyProperty2
    //         property1CaryfyProperty2P="/footer-logo@2x.png"
    //         property1CaryfyProperty2Width="146.4px"
    //         property1CaryfyProperty2Height="40px"
    //         property1CaryfyProperty2Position="relative"
    //       />
    //     </div>
    //     <div className="flex-1 shrink-0 flex flex-row items-center justify-center min-w-[328px]">
    //       <div className="flex-1 relative leading-[30px] font-light opacity-[0.5]">
    //         © Copyright Caryfy.AI. All rights reserved.
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <footer className="self-stretch bg-darkslategray-100 flex flex-col items-center justify-center py-8 px-4 box-border min-w-[360px] text-left text-lg text-white font-lato">
      <div className="w-full flex flex-row flex-wrap items-center justify-start gap-[8px] max-w-[1280px]">
        <div className="flex flex-row items-center justify-start min-w-[328px]">
          <div className="relative leading-[30px] uppercase font-black">
            Care Indices
          </div>
        </div>
        <div className="flex-1 flex flex-row items-center justify-center min-w-[328px] text-right text-base font-open-sans">
          <div className="flex-1 relative leading-[30px] font-semibold opacity-[0.5]">
            © Copyright CareIndices.com. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
