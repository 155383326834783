import Header from "../components/Header";
import OperationalManagementContainer from "../components/OperationalManagementContainer";
import ProgressBar from "../components/ProgressBar";
import Footer from "../components/Footer";
import React, { useState, useEffect } from "react";
import { commonService } from '../services/commonService';

import { useNavigate } from 'react-router-dom';

const Question = () => {

  const categoryArray = JSON.parse(localStorage.getItem('categoryArray')) || [];

  const [apiData, setApiData] = useState(null);

  const [allQuestions, setAllQuestions] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(!localStorage.getItem("userId")){
          localStorage.setItem('survey','quicksurvey');
          if(localStorage.getItem('survey')=='fullsurvey'){
            navigate('/fullsurvey');
          }else{
            navigate('/');
          }
        }
        const result = await commonService.get('categoryList'); // Update the endpoint accordingly
        const filteredCategories = result.filter(category =>
          categoryArray.includes(category.id)
        );
        setApiData(filteredCategories);
        const allQuestions = filteredCategories.flatMap(category => category.questions);
        setAllQuestions(allQuestions);
        // alert(allQuestions);
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[360px]">
      <Header logo="/header_logo@1x.png" headerWidth="100%" />

      {/* {apiData ? (
       <>
       {apiData.map((item) => (
      <p>{item.CategoryName}</p>
     ))}
       </>
      
     ) : (
       <p>Loading...</p>
     )} */}

      <OperationalManagementContainer allQuestions={allQuestions} categories={apiData}  />
      {/* <ProgressBar
        progressBarWidth="50%"
        rectangleDivBackgroundColor="#16a15c"
      /> */}
      <Footer dimensionText="/footer_logo@1x.png" footerMaxWidth="unset" />
    </div>
  );
};

export default Question;
