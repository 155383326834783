import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { commonService } from '../services/commonService';

// import './styles.css';


import { useNavigate } from 'react-router-dom';

const SelectCategory = () => {
  const navigate = useNavigate();

  const [apiData, setApiData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!localStorage.getItem("userId")) {
          localStorage.setItem('survey', 'quicksurvey');
          if (localStorage.getItem('survey') == 'fullsurvey') {
            navigate('/fullsurvey');
          } else {
            navigate('/');
          }
        }

        const result = await commonService.get('category'); // Update the endpoint accordingly
        setApiData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const onCategorySelect = (itemId) => {

    // Check if the item is already in the selectedItems array
    if (selectedItems.includes(itemId)) {
      // Remove the item from the selectedItems array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItemId) => selectedItemId !== itemId)
      );
    } else {
      if (selectedItems.length == 2) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
      if (selectedItems.length >= 3) {
        setButtonDisabled(false);
        alert("Please Select Any Three")
        return
      }

      // Add the item to the selectedItems array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    }
  };

  const onSave = () => {
    localStorage.setItem('categoryArray', JSON.stringify(selectedItems));
    navigate('/question');

  };
  return (
    <div className="relative bg-white w-full flex flex-col items-center justify-start min-w-[360px]">
      <Header logo="/header_logo@1x.png" headerWidth="100%" />
      <section className="w-full flex flex-col items-center justify-start py-0 px-4 box-border min-w-[360px] max-w-[1440px] text-left text-13xl text-gray font-inter">
        <div className="w-full flex flex-col items-start justify-center py-16 px-0 box-border gap-[24px] max-w-[1276px]">
          <h2 className="m-0 self-stretch relative text-inherit leading-[140%] font-medium font-inherit">
            Select 3 key priorities and gain focused insights to guide your immediate action steps. Perfect for a swift assessment and starting point..
          </h2>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[24px] text-lg question-category-list">
            {apiData ? (
              <>
                {apiData.map((item) => (
                  <div key={item.id}
                    className={`rounded-lg bg-whitesmoke w-[236px] h-20 flex flex-row items-center justify-start p-4 gap-[12px] sm:w-full ${selectedItems.includes(item.id) ? 'border-2 border-solid border-mediumseagreen' : ''
                      }`}
                    onClick={() => onCategorySelect(item.id)}>
                    <img
                      className="relative w-8 h-8 overflow-hidden shrink-0"
                      alt=""
                      src={selectedItems.includes(item.id) ? '/item-checked.svg' : '/item-unchecked.svg'}

                    />
                    <div className="flex-1 relative leading-[140%] font-semibold">
                      {item.CategoryName}
                    </div>
                  </div>
                ))}
              </>

            ) : (
              <p>Loading...</p>
            )}




          </div>
          {/* <div className="self-stretch rounded bg-lavenderblush flex flex-row items-start justify-start p-2 gap-[8px] text-base text-tomato">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/error-icon.svg"
            />
            <div className="flex-1 relative leading-[140%] font-medium">
              Select Any 3 Options
            </div>
          </div> */}
          <button className={`cursor-pointer [border:none] py-0 px-[52px] ${selectedItems.length < 3 ? 'bg-darkgray' : 'bg-tomato'
            }  rounded-lg h-[52px] flex flex-col items-center justify-center box-border`} disabled={selectedItems.length < 3} onClick={() => onSave()}>
            <div className="relative text-base leading-[30px] font-semibold font-inter text-white text-center" >
              Next
            </div>
          </button>
        </div>
      </section>
      <Footer dimensionText="/footer_logo@1x.png" footerMaxWidth="unset" />
    </div>
  );
};

export default SelectCategory;
