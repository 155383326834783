// apiService.js
const devapiUrl = 'http://127.0.0.1:8000/';
const prodapiUrl = 'https://careindicesapi.azurewebsites.net/';
const env = process.env.NODE_ENV;
const apiUrl = env === 'production' ? prodapiUrl : devapiUrl;

// Helper function to handle API responses
const handleResponse = (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  return response.json();
};

// Wrapper function for making GET requests
const get = (endpoint) => {
  const url = `${apiUrl}${endpoint}`;

  return fetch(url)
    .then(handleResponse)
    .catch((error) => {
      console.error('Error making GET request:', error);
      throw error;
    });
};

// Wrapper function for making POST requests
const post = (endpoint, data) => {
  console.log('env variable ', env);
  const url = `${apiUrl}${endpoint}`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch((error) => {
      console.error('Error making POST request:', error);
      throw error;
    });
};

export const commonService = {
  get,
  post,
};
