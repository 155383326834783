import { useMemo } from "react";
import Property1CaryfyProperty2 from "./Property1CaryfyProperty2";

const Header = ({ logo, headerWidth }) => {
  const headerStyle = useMemo(() => {
    return {
      width: headerWidth,
    };
  }, [headerWidth]);

  return (
    // <div
    //   className="w-[1440px] flex flex-col items-center justify-start py-10 px-4 box-border min-w-[320px] max-w-[1440px]"
    //   style={headerStyle}
    // >
    //   <div className="w-full flex flex-row flex-wrap items-center justify-start max-w-[1276px]">
    //     <div className="flex-1 flex flex-col items-start justify-center">
    //       <Property1CaryfyProperty2
    //         property1CaryfyProperty2P="/logo@2x.png"
    //         property1CaryfyProperty2Width="204.9px"
    //         property1CaryfyProperty2Height="56px"
    //         property1CaryfyProperty2Position="relative"
    //       />
    //     </div>
    //   </div>
    // </div>
    <header className="self-stretch flex flex-col items-center justify-start py-10 px-4 box-border min-w-[320px] text-left text-5xl text-coral-100 font-lato">
      <div className="w-full flex flex-row flex-wrap items-center justify-start max-w-[1280px]">
        <div className="flex-1 flex flex-col items-start justify-center">
          <div className="relative leading-[30px] uppercase font-black">
            <span>{`Care `}</span>
            <span className="text-gray-400">Indices</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
